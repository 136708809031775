import React from "react";
import { Link } from "react-router-dom";
// import Img
import BigImg from "../../assets/img/common/shah-choudhury.png"
import BigImg2 from "../../assets/img/common/sandeep-banoor.png"
// import AuthImg from "../../assets/img/news/author-1.png"


const BigNews = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="news_area_top_left">
            <br></br>
            <a href="news-details.html">
              <img src={BigImg2} alt="img" />
            </a>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="news_area_top_right">
            <p>
            A Passionate Travel Enthusiast Ready to Lead. With a deep-rooted passion for travel and a wealth of experience in the industry, Sandeep Banoor is poised to drive Travel Ark Limited forward as Director. His visionary leadership, coupled with a keen understanding of customer needs and industry trends, promises to elevate our company to new heights. With Sandeep at the helm, we're embarking on an exciting journey of innovation and growth.
            </p>
            <div className="news_author_area">
              <div className="news_author_area_name">
                <h4>Sandeep Reddy Banoor</h4>
                <p>
                  <Link to="/">Founder & Director</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3">
          <div className="news_area_top_left">
            <br></br>
            <a href="news-details.html">
              <img src={BigImg} alt="img" />
            </a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="news_area_top_right">
            <p>
            A Visionary Leader with Immersive Business Acumen. With a wealth of knowledge and expertise in the business landscape, Shah Choudhury spearheads Travel Ark Limited towards a future of innovation and growth. His deep understanding of industry dynamics, coupled with a strategic mindset, ensures that Travel Ark remains at the forefront of the travel sector. With Shah's leadership, we embark on a journey of success, driven by insight, resilience, and a commitment to excellence.
            </p>
            <div className="news_author_area">
              <div className="news_author_area_name">
                <h4>Shah Choudhury</h4>
                <p>
                  <Link to="/">Founder & Chief Executive Officer (CEO)</Link>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default BigNews;
